* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --first-color: hsl(28, 86%, 52%);
  --title-color: hsl(28, 86%, 52%);
  --body-font: "Montserrat", sans-serif;
  --second-font: "Dancing Script", cursive;
  --h2-font-size: 2.2rem;
  --small-font-size: 0.813rem;
  --font-semi-bold: 600;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
  color: var(--title-color);
  /* font-weight: var(--font-semi-bold); */
  transition: color 0.4s;
}

.linkPadding {
  padding: 5px;
}

img {
  max-width: 100%;
}

.app {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* background-color: var(--theme-background); */
  /* color: var(--theme-text); */
  transition: background-color 0.25s ease-in-out;
}

.plus {
  color: green;
  font-weight: bold;
}

.minus {
  color: red;
  font-weight: bold;
}

.table-text {
  font-weight: bold;
}

.align-right {
  text-align: right;
}

.align-left {
  text-align: left;
}

.homeShill {
  text-align: center;
  margin-bottom: 7px;
}

.footer {
  display: flex;
  flex-flow: column nowrap;
  /* min-height: 25vh;  or the minimum height of your content */
  padding: 15px;
  align-items: center;
}

.about {
  display: flex;
  flex-flow: column nowrap;
  min-height: 60vh; /* or the minimum height of your content */
  padding: 15px;
  align-items: center;
}


/* blog */
.blogIndex {
  max-width: 1200px;
  padding: 15px;
}

.blogIndexItem {
  display: flex;
  grid-template-columns: 0.6fr 1.4fr;
  gap: 25px;
  margin-top: 50px;
  margin-bottom: 50px;
  flex-direction: column;
}


.blogIndexImage {
  width: 350px;
  min-width: 350px;
}

.blogIndexText h2 {
  margin: 0;
  text-align: left;
  font-size: 1.8rem;
}

.blogIndexText a {
  text-decoration: none;
  padding: 0;
}

.blogIndexInfo {
  text-align: left;
  font-size: 0.8rem;
}

.blogIndexSummary {
  margin-top: 12px;
  text-align: left;
  line-height: 1.4rem;
}


.blogPostPage {
  max-width: 1200px;
  padding: 15px;
  margin-top: 50px;
  margin-bottom: 50px;
}

.blogPostPage h1{
  margin: 20px 0 0px;
}

.blogPostPage p{
  margin: 20px 0 0px;
}

.blogPostPage ol, ul{
  margin: 20px 0 0px;
  padding-left: 30px;
}

.blogPostContent {
  text-align: left;
}

.blogPostImage {
  margin-bottom: 30px;
}

.blogPostTime {
  margin-bottom: 30px;
  font-size: 0.8rem;
}
/* End blog */



/* navbar */
nav {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  grid-area: nav;
  /* background-color: var(--theme-background); */
  padding: 5px;
  outline: none;
  margin-bottom: 7px;
}

.nav_link {
  color: var(--title-color);
  font-weight: var(--font-semi-bold);
  transition: color 0.4s;
  font-size: 1.6rem;
}

.homeLinkAligh {
  display: flex;
  align-items: center;
}

.nav_button {
  /* background-color: var(--theme-background); */
  color: var(--light-text);
  padding: 10px;
  font-size: 1em;
  border: solid 2px transparent;
  margin: 3px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  } 100% {
    top: -3px;
    transform: rotate(90deg);
  }
}

@keyframes spin-speaking {
  0% {
    transform: rotate(0deg);
  } 100% {
    top: 3px;
    transform: rotate(90deg);
  }
}

/* media queries */
@media screen and (min-width: 350px) {

  .about_headshot {
    width: 300px;
  }

  .work_container_image > img, .work_image {
    max-width: 300px;
  }

  .contact-form {
    min-width: 300px;
  }

  .blog-content, .talks {
    padding: 5%;
  }

  .speaking_CTA {
    margin: 5%;
  }
}

@media screen and (min-width: 950px) {
  nav {
    flex-direction: row;
  }
  .blogIndexItem {
    flex-direction: row;
  }
}

@media screen and (min-width: 800px) {
  .work_container_image > img, .work_image {
    max-width: 300px;
  }
}

@media screen and (min-width: 900px) {
  .work_container_image > img, .work_image {
    max-width: 400px;
  }
}

@media screen and (max-width: 950px){  
  .blogIndexItem .blogIndexImage {
    width: 100%;
  }
}